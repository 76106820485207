import { render, staticRenderFns } from "./uni-verse.vue?vue&type=template&id=258f49ef&scoped=true&"
import script from "./uni-verse.vue?vue&type=script&lang=js&"
export * from "./uni-verse.vue?vue&type=script&lang=js&"
import style0 from "./uni-verse.vue?vue&type=style&index=0&id=258f49ef&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "258f49ef",
  null
  
)

export default component.exports