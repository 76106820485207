<template>
  <div class="home">
    <h1><a href="/uni-verse">UNI-VERSE →</a></h1>
    <h1><a href="/alcx">ALCHEMIX →</a></h1>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'Home',
  components: {
    /*HelloWorld*/
  }
}
</script>

<style scoped>

a {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

</style>
